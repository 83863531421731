import React from 'react';

const Calendar = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: 'none', marginRight: 5 }}
    >
      <path
        d="M16.5 8.33332H1.5M12.3333 1.66666V4.99999M5.66667 1.66666V4.99999M5.5 18.3333H12.5C13.9001 18.3333 14.6002 18.3333 15.135 18.0608C15.6054 17.8212 15.9878 17.4387 16.2275 16.9683C16.5 16.4335 16.5 15.7335 16.5 14.3333V7.33332C16.5 5.93319 16.5 5.23313 16.2275 4.69835C15.9878 4.22794 15.6054 3.84549 15.135 3.60581C14.6002 3.33332 13.9001 3.33332 12.5 3.33332H5.5C4.09987 3.33332 3.3998 3.33332 2.86502 3.60581C2.39462 3.84549 2.01217 4.22794 1.77248 4.69835C1.5 5.23313 1.5 5.93319 1.5 7.33332V14.3333C1.5 15.7335 1.5 16.4335 1.77248 16.9683C2.01217 17.4387 2.39462 17.8212 2.86502 18.0608C3.3998 18.3333 4.09987 18.3333 5.5 18.3333Z"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Calendar;
