import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Form, Field } from 'react-final-form';
import css from './ListingForm.module.css';

const CustomSelect = ({
  name,
  placeholder,
  value,
  startIcon,
  endIcon,
  label,
  options,
  onChange,
}) => {
  return (
    <div>
      <label className="label">{label}</label>
      <span className={css.selectContainer}>
        <select name={name} onChange={onChange}>
          <option key="" value=""></option>
          {options.map((val, ind) => (
            <option key={val.timeOfDay+ind} value={`${val.timestamp}-${val.timeOfDay}`}>
              {val.timeOfDay} 
            </option>
          ))}
        </select>
        <span className={css.endIcon}>{endIcon}</span>
      </span>

      {/* <Field name={name} value={value} onChange={onChange} component="input" type="select">
        {props => {
          return (
            <div>
              <TextField
                variant="standard"
                className="customField"
                select
                SelectProps={{ IconComponent: () => null }}
                name={props.input.name}
                value={props.value}
                onChange={props.onChange}
                placeholder={placeholder}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{startIcon}</InputAdornment>,
                  endAdornment: <InputAdornment position="end">{endIcon}</InputAdornment>,
                  disableUnderline: true,
                }}
              >
                {options.map((val, ind) => (
                  <li key={val.timeOfDay} value={`${val.timestamp}-${val.timeOfDay}`}>
                    {val.timeOfDay}
                  </li>
                ))}
              </TextField>
            </div>
          );
        }}
      </Field> */}
    </div>
  );
};

export default CustomSelect;
