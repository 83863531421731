import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
// import Slider, { Range } from 'rc-slider';
// import 'rc-slider/assets/index.css';
import css from './VideoPlayer.module.css';
import Pause from '../Icons/Pause';

const VideoPlayer = props => {
  let playerRef = useRef(null);
  const [isPlaying, setPlay] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  //   const [volume, setVolume] = useState(0.8);
  //   const [showControls, setShowControls] = useState(false);
  //   const handleVolumeChange = value => {
  // setVolume(value);
  //   };

  const handleSeekChange = e => {
    setPlayed(parseFloat(e));
  };

  const handleSeekMouseUp = e => {
    playerRef.seekTo(parseFloat(e));
  };

  return (
    <div className={css.playerWrapper} style={{height:'100%'}}>
      <ReactPlayer
        className={css.reactPlayer}
        url={props.url}
        playing={isPlaying}
        ref={player => {
          playerRef = player;
        }}
        controls
        onEnded={() => {
          setPlay(false);
        }}
        onDuration={value => {
          setDuration(value);
        }}
        onProgress={progress => {
          setPlayed(progress.playedSeconds);
        }}
        width="100%"
        height='100%'
      />
      {/* <div className={css.playerOverlay}>
        <Pause onClick={() => setPlay(true)} />
        <Slider
          className="rc-volume"
          value={played}
          min={0}
          max={duration}
          step={1}
          onChange={handleSeekChange}
          onAfterChange={handleSeekMouseUp}
        />
      </div> */}
    </div>
  );
};

export default VideoPlayer;
