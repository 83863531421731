import config from '../config';
import moment from 'moment';
import {
  getStartHours,
  getEndHours,
  isInRange,
  isSameDate,
  isDayMomentInsideRange,
  resetToStartOfDay,
  timeOfDayFromLocalToTimeZone,
  timeOfDayFromTimeZoneToLocal,
  dateIsAfter,
  findNextBoundary,
  timestampToDate,
  localizeAndFormatTime,
  monthIdStringInTimeZone,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
} from './dates';

const MAX_TIME_SLOTS_RANGE = config.dayCountAvailableForBooking;

const getAvailableStartTimes = (intl, timeZone, bookingStart, timeSlotsOnSelectedDate) => {
  if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
    return [];
  }
  const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);

  const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
    const startDate = t.attributes.start;
    const endDate = t.attributes.end;
    const nextDate = resetToStartOfDay(bookingStartDate, timeZone, 1);

    // If the start date is after timeslot start, use the start date.
    // Otherwise use the timeslot start time.
    const startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;

    // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
    // Otherwise use the end of the timeslot.
    const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;

    const hours = getStartHours(intl, timeZone, startLimit, endLimit);

    // return availableHours.concat(hours); old code
    return hours;
  }, []);
  return allHours;
};

const getTimeSlots = (timeSlots, date, timeZone) => {

  const dateF = moment(date).format('YYYY-MM-DD');
  let res =
    timeSlots && timeSlots[0]
      ? timeSlots.filter(t => {
          let compareDate = moment(t.attributes.start).format('YYYY-MM-DD');

          if (moment(t.attributes.start).format('YYYY-MM-DD') === dateF) {
            return t;
            // return isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone);
          }
        })
      : [];
  return res;
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  // const monthId = monthIdStringInTimeZone(date, timeZone);
  const monthId = moment(date).format('YYYY-MM');
  console.log('monthId', {date,monthId, monthlyTimeSlots});


  let res =
    monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
      ? monthlyTimeSlots[monthId].timeSlots
      : [];

  return res;

  // return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
  //   ? []
  //   : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
  //   ? monthlyTimeSlots[monthId].timeSlots
  //   : [];
};

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

export { getAvailableStartTimes, getTimeSlots, getMonthlyTimeSlots, endOfRange };
