import React from 'react';
import SingleComment from '../../components/SingleComment/SingleComment';
import moment from 'moment';

const CommentsContainer = props => {
  const { reviews } = props;

  const reviewComment = reviews.map((msg, ind) => {
    const attr = msg.attributes;
    const author = msg.author.attributes;
    const createdAt = moment(attr.createdAt).format('DD MMM YYYY');

    return (
      <SingleComment
        rating={attr.rating || 0}
        content={attr.content || ''}
        createdAt={createdAt}
        userName={author.profile.displayName}
        profileImage={author.profileImage || author.profile.abbreviatedName}
      />
    );
  });

  return <div style={{ marginTop: 20 }}>{reviewComment}</div>;
};

export default CommentsContainer;
