import React from 'react';
import css from './ListingServiceOffered.module.css';

const ListServiceStyle = props => {
  return (
    <div className={css.ListStyleBullet}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#D1FADF" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.0964 7.39004L9.93638 14.3L8.03638 12.27C7.68638 11.94 7.13638 11.92 6.73638 12.2C6.34638 12.49 6.23638 13 6.47638 13.41L8.72638 17.07C8.94638 17.41 9.32638 17.62 9.75638 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
          fill="#12B76A"
        />
      </svg>
      <p style={{ margin: 0, marginLeft: 10, fontSize: 16, letterSpacing: 0.5, color: '#667085' }}>
        {props.text}
      </p>
    </div>
  );
};

export default ListServiceStyle;
