import React from 'react';
import ListingHeader from '../../components/ListingHeader/ListingHeader';
import ListingServiceOffered from '../../components/ListingServiceOffered/ListingServiceOffered';
import CommentsContainer from '../CommentsContainer/CommentsContainer';
import css from './MainListing.module.css';

const MainListing = props => {
  const {
    currentListing,
    title,
    description,
    monthlyTimeSlots,
    reviews,
    currentAuthor,
    handleSubmit,
    intl,
    location,
    lineItems,
    isOwnListing,
    unitType,
    onFetchTransactionLineItems,
    currentUser,
    isAuthenticated
  } = props;

  let favArr = currentUser?.attributes?.profile?.publicData?.favoriteListings;
  let crlistingId = currentListing?.id?.uuid;
  let isFav = favArr?.indexOf(crlistingId) > -1 ? true : false;

  const attr = currentListing?.attributes;
  const publicData = attr?.publicData;
  const musicStyles = publicData?.musicStyles || [];
  const travelTo = publicData?.city?.split(',') || [];
  const userImage =
    currentAuthor?.profileImage?.attributes?.variants['landscape-crop2x']?.url || title;

  const serviceOffered = publicData?.yogaStyles;
  // Object.keys(publicData?.servicePrice).length > 0
  //   ? publicData?.yogaStyles
  //   : publicData?.yogaStyles;
  // ? Object.keys(publicData?.servicePrice)
  const videoUrlFromDesc = attr?.description?.match(/\bhttps?:\/\/\S+/gi) || null;
  const videoUrl =
    Array.isArray(videoUrlFromDesc) && videoUrlFromDesc.length ? videoUrlFromDesc[0] : null;

  return (
    <div className={css.MainListingContainer}>
      <ListingHeader
        title={title}
        description={''}
        currentListing={currentListing}
        currentAuthor={currentAuthor}
        userImage={userImage}
        musicStyles={musicStyles}
        videoUrl={videoUrl}
        location={location}
      />
      {true ? (
        <div>
          <ListingServiceOffered
            musicStyles={musicStyles}
            travelTo={travelTo}
            title={title}
            serviceOffered={serviceOffered}
            serviceWithPrice={publicData?.servicePrice || {}}
            description={description}
            handleSubmit={handleSubmit}
            monthlyTimeSlots={monthlyTimeSlots}
            currentListing={currentListing}
            onFetchTimeSlots={props.onFetchTimeSlots}
            intl={intl}
            onContactUser={props.onContactUser}
            lineItems={lineItems}
            isOwnListing={isOwnListing}
            unitType={unitType}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            isFav={isFav}
            isAuthenticated={isAuthenticated}
            currentUser={currentUser}
          />
          <CommentsContainer reviews={reviews} />
        </div>
      ) : (
        <div className={css.listingForm}>{/* <ListingForm /> */}</div>
      )}
    </div>
  );
};

export default MainListing;
