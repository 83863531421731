import React, { useState } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import css from './ListingServiceOffered.module.css';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const SelectService = props => {
  const {
    requestHandler,
    serviceOffered,
    serviceWithPrice,
    selectedServiceHandler,
    bookingState,
    price,
  } = props;
  console.log('this is the service',bookingState?.selectedService , serviceOffered)
  const [value, setValue] = useState(bookingState?.selectedService || serviceOffered[0] || '');
  const handleChange = event => {
    setValue(event.target.value);
  };
  console.log('value', value);
  let isDisabledButton = value == '' ? css.disabled : ' ';

  return (
    <div className={css.containerService}>
      <FormControl component="fieldset" style={{ width: '100%' }}>
        <FormLabel component="legend" className={css.SelectServiceLabel}>
          Select Service
        </FormLabel>
        <RadioGroup aria-label="service" name="service" value={value} onChange={handleChange}>
          {serviceOffered.map((service, ind) => {
            const priceCalculated = serviceWithPrice[service] || price;
            return (
              <div className={css.radioContainer} key={value + ind}>
                <FormControlLabel
                  className={css.FormControlLabel}
                  value={service}
                  color="green"
                  control={<Radio />}
                  label={
                    service !== 'pianist'
                      ? capitalizeFirstLetter(service).replaceAll('_', ' ')
                      : 'Keys'
                    // capitalizeFirstLetter(service).replaceAll('_', ' ')
                  }
                />
                <span className={css.perHour}>${priceCalculated}/Hour</span>
              </div>
            );
          })}
        </RadioGroup>
      </FormControl>
      <div className={css.requestBooking + ' ' + css.applyContainer}>
        <button
          className={css.button + ' ' + css.contact + ' ' + css.cancel}
          onClick={requestHandler}
        >
          Cancel
        </button>
        <button
          className={
            css.button + ' ' + css.bookingButton + ' ' + css.apply + ' ' + isDisabledButton
          }
          onClick={() => {
            selectedServiceHandler(value);
            requestHandler();
          }}
        >
          {' '}
          Apply
        </button>
      </div>
    </div>
  );
};

export default SelectService;
