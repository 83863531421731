import { Avatar } from '@material-ui/core';
import React from 'react';
import css from './SingleComment.module.css';
import user from '../../assets/user.svg';
import Star from '../Icons/Star';

const SingleComment = props => {

  const showRating = () => {
    let starArr = [];
    const remRating = 5 - props.rating;
    for (let i = props.rating; i >= 1; i--) {
      starArr.push(<Star filled />);
    }

    for (let i = remRating; i >= 1; i--) {
      starArr.push(<Star />);
    }
    return starArr;
  };

  return (
    <div className={css.comment}>
      <div className={css.userAvatar}>
        <Avatar alt="user" src={props.profileImage} className={css.userImage}>
          {props.profileImage}
        </Avatar>
        <p className={css.userName}>{props.userName}</p>
        <p className={css.userDate}>{props?.createdAt}</p>
      </div>
      <div className={css.msg}>
        {showRating()}
        <p>{props?.content}</p>
      </div>
    </div>
  );
};

export default SingleComment;
