import React, { useState, useEffect } from 'react';
import css from './ListingServiceOffered.module.css';
import { Form, Field } from 'react-final-form';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CustomSelect from '../ListingForm/CustomSelect';
import moment from 'moment';
import { endOfRange } from '../../util/time';
import {
  isInRange,
  dateIsAfter,
  nextMonthFn,
  resetToStartOfDay,
  monthIdStringInTimeZone,
  prevMonthFn,
} from '../../util/dates';

const DownArrow = props => (
  <p>
    <span style={{ marginRight: '5px' }}>{props.time}</span>
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: 'none' }}
    >
      <path
        d="M1 1.5L6 6.5L11 1.5"
        stroke="#667085"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </p>
);

const SelectDate = props => {
  const TODAY = new Date();
  const { requestHandler, selectDateHandler, selectedDateHandler, monthlyTimeSlots, selectedDatesTimesHandler } = props;
  const [dateTime, setDateTime] = useState({
    bookingStartTime: '',
    bookingEndTime: '',
    bookingStartDate: '',
    bookingStartStr: '',
    bookingEndStr: '',
  });
  const [value, onChange] = useState([]);

  const fetchMonthData = date => {
    const { listingId, timeZone, onFetchTimeSlots } = props;
    const endOfRangeDate = endOfRange(TODAY, timeZone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  };

  const onMonthClick = monthFn => {
    const { onMonthChanged, timeZone } = props;
    let latestMonth = monthFn(props.currentMonth, timeZone);
    props.setCurrentMonth(latestMonth);

    fetchMonthData(latestMonth, timeZone);

    const monthId = monthIdStringInTimeZone(latestMonth, timeZone);
    const currentMonthData = props.monthlyTimeSlots[monthId];

    if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
      fetchMonthData(latestMonth, timeZone);
    }

    if (onMonthChanged) {
      onMonthChanged(monthId);
    }
  };

  function isDateInArray(currentValue, array) {
    return array.some(item => item.getTime() === currentValue.getTime());
  }

  const handleDateChange = (valueCurr, event) => {
    if (!isDateInArray(valueCurr, value)) {
      setDateTime({
        ...dateTime,
        bookingStartDate: [...value, valueCurr],
        bookingEndDate: [...value, valueCurr],
      });
      selectedDateHandler({
        ...dateTime,
        bookingStartDate: [...value, valueCurr],
        bookingEndDate: [...value, valueCurr],
      });
      onChange([valueCurr]);
    } else {
      const totalDatesArr = value.filter(
        date => new Date(date).toString() !== valueCurr.toString()
      );
      setDateTime({
        ...dateTime,
        bookingStartDate: totalDatesArr,
        bookingEndDate: totalDatesArr,
      });
      selectedDateHandler({
        ...dateTime,
        bookingStartDate: totalDatesArr,
        bookingEndDate: totalDatesArr,
      });
      onChange(totalDatesArr);
    }
  };

  const timeHandler = e => {
    let timeArr = e.target.value.split('-');
    let bookingName = '';
    if (e.target.name === 'bookingStartTime') {
      bookingName = 'bookingStartStr';
    } else {
      bookingName = 'bookingEndStr';
    }
    setDateTime({ ...dateTime, [e.target.name]: timeArr[0], [bookingName]: timeArr[1] });
  };

  const getModifiedDateTime = () => {
    let startHours = 0;
    let endHours = 0;
    let startTimeInt = parseInt(dateTime.bookingStartStr);
    let endTimeInt = parseInt(dateTime.bookingEndStr);
    console.log('dateTime', dateTime);

    if (dateTime.bookingStartStr.includes('PM') && startTimeInt < 12) {
      startHours = 12 + startTimeInt;
    } else {
      startHours = startTimeInt;
    }

    if (dateTime.bookingEndStr.includes('PM') && endTimeInt < 12) {
      endHours = 12 + endTimeInt;
    } else {
      endHours = endTimeInt;
    }

    if (dateTime.bookingStartStr.includes(':30')) {
      startHours += 0.5;
    }

    if (dateTime.bookingEndStr.includes(':30')) {
      endHours += 0.5;
    }

    const bookingStartDate = moment(dateTime.bookingStartDate[0]).add(startHours, 'hours')['_d'];
    const bookingEndDate = moment(dateTime.bookingEndDate[0]).add(endHours, 'hours')['_d'];

    let startTime = moment(dateTime.bookingStartStr, 'HH:mm:ss a');
    let endTime = moment(dateTime.bookingEndStr, 'HH:mm:ss a');
    let duration = moment.duration(endTime.diff(startTime));
    let hours = parseInt(duration.asHours());
    let minutes = parseInt(duration.asMinutes()) % 60;
    let calculatedHours = 0;

    if (minutes === 60) {
      calculatedHours = hours + 1;
    } else if (minutes === 30) {
      calculatedHours = hours + 0.5;
    } else {
      calculatedHours = hours;
    }
    console.log('calculatedHours');
    return { bookingStartDate, bookingEndDate, calculatedHours, minutes, startHours, endHours };
  };

  const modifyDateWithTime = () => {
    const { bookingStartDate, bookingEndDate, calculatedHours, minutes, startHours, endHours } = getModifiedDateTime();
    console.log('dateTimedateTimedateTime', dateTime)
    selectedDateHandler({ ...dateTime, bookingStartDate, bookingEndDate, hours: calculatedHours, minutes, totalSelectedDate: value, startHours, endHours });
    selectedDatesTimesHandler([ ...props.bookingState.bookingDatesTimes, { bookingStartDate, bookingEndDate, startHours, endHours, startTimeStamp:dateTime.bookingStartTime, endTimeStamp:dateTime.bookingEndTime, checked:false }]);
    setTimeout(()=>{
      selectDateHandler();
    },100)
  };

  const disableDate = ({ activeStartDate, date, view }) => {
    let iterableKeys = Object.keys(monthlyTimeSlots);
    let isMatched = true;
    let pastDate = date < new Date();

    if (pastDate) {
      return true;
    } else if (!iterableKeys.length) {
      return false;
    }

    for (let i = 0; i < iterableKeys.length; i++) {
      let datesArr = monthlyTimeSlots[iterableKeys[i]]?.timeSlots || [];

      if (!datesArr.length) {
        return false;
      }

      if (datesArr.length) {
        for (let j = 0; j < datesArr.length; j++) {
          const selectedDate = new Date(datesArr[j]?.attributes?.start);
          const currentDate = new Date(date);
          if (selectedDate.toDateString() === currentDate.toDateString()) {
            isMatched = false;
            break;
          }
        }
      }
    }
    return isMatched;
  };

  const onMonthChange = action => {
    if (action === 'next' || action === 'onChange') {
      onMonthClick(nextMonthFn);
      return;
    }

    if (action === 'prev') {
      onMonthClick(prevMonthFn);
      return;
    }
  };

  let isDisabledButton =
    dateTime?.bookingStartTime == '' ||
    dateTime?.bookingEndTime == '' ||
    dateTime?.bookingStartDate == ''
      ? css.disabled
      : ' ';

  const generateTime = time => {
    let decimalTimeString = time;
    let decimalTime = parseFloat(decimalTimeString);
    decimalTime = decimalTime * 60 * 60;
    let hours = Math.floor(decimalTime / (60 * 60));
    decimalTime = decimalTime - hours * 60 * 60;
    let minutes = Math.floor(decimalTime / 60);
    decimalTime = decimalTime - minutes * 60;
    let seconds = Math.round(decimalTime);
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return { hours, minutes };
  };

  const hasTime = () => {
    console.log('props.availableStartTimes', dateTime);
    if (!props.availableStartTimes.length) {
      let timesArr = [];
      let i = 1;
      while (i <= 24) {
        let { hours, minutes } = generateTime(i);
        let amOrPm = hours >= 12 ? 'PM' : 'AM';
        let hoursT = hours % 12 || 12;
        if (dateTime.bookingStartDate !== '') {
          let startHours = 0;
          let startTimeInt = parseInt(`${hoursT}:${minutes} ${amOrPm}`);

          if (`${hoursT}:${minutes} ${amOrPm}`.includes('PM') && startTimeInt < 12) {
            startHours = 12 + startTimeInt;
          } else {
            startHours = startTimeInt;
          }

          if (`${hoursT}:${minutes} ${amOrPm}`.includes(':30')) {
            startHours += 0.5;
          }

          const bookingStartDate = moment(dateTime.bookingStartDate).add(startHours, 'hours')['_d'];
          const timeStampByDate = new Date(bookingStartDate);
          const timeZoneDate = timeStampByDate.toLocaleString('en-US', {
            timeZone: 'America/New_York',
          });
          const NewDate = new Date(timeZoneDate);
          const unixTimestamp = Math.floor(NewDate.getTime());
          console.log(unixTimestamp, { unixTimestamp, NewDate });

          timesArr.push({
            timestamp: unixTimestamp,
            timeOfDay: `${hoursT}:${minutes} ${amOrPm}`,
          });
        }

        i = i + 0.5;
      }
      console.log('timesArr', timesArr);
      return props.availableStartTimes;
      return timesArr;
    }
    return props.availableStartTimes;
  };

  const haveTime = hasTime();

  console.log('haveTime', haveTime);
  console.log('date values', [...new Set(value)]);

  const isSameDay = (dateA, dateB) => {
    return (
      dateA.getDate() === dateB.getDate() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getFullYear() === dateB.getFullYear()
    );
  };

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const isSelected = value.some(selectedDate => isSameDay(selectedDate, date));
      return isSelected ? 'selected-date' : '';
    }
    return '';
  };
  console.log('value dates', value);
  return (
    <div className={css.containerService}>
      <div>
        <p className={css.selectDateLabel}>Select Date</p>
        <Calendar
          onChange={handleDateChange}
          value={value.length > 0 ? value : [new Date()]}
          className={css.dateCalendar}
          tileClassName={tileClassName}
          selectRange={false}
          onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
            console.log('change date', { action, activeStartDate, value, view });
            onMonthChange(action);
          }}
          tileDisabled={disableDate}
        />
      </div>

      <div>
        <Form
          onSubmit={() => {}}
          initialValues={{ stooge: 'larry', employed: false }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <CustomSelect
                label="Select Start and End Time"
                value={dateTime['bookingStartTime'] || ''}
                onChange={timeHandler}
                name="bookingStartTime"
                endIcon={<DownArrow time="" />}
                options={haveTime}
              />
              <CustomSelect
                value={dateTime['bookingEndTime'] || ''}
                onChange={timeHandler}
                name="bookingEndTime"
                endIcon={<DownArrow time="" />}
                options={haveTime}
              />
            </form>
          )}
        />
      </div>

      <div className={css.requestBooking + ' ' + css.applyContainer}>
        <button
          className={css.button + ' ' + css.contact + ' ' + css.cancel}
          onClick={selectDateHandler}
        >
          Cancel
        </button>
        <button
          className={
            css.button + ' ' + css.bookingButton + ' ' + css.apply + ' ' + isDisabledButton
          }
          onClick={modifyDateWithTime}
        >
          {' '}
          Apply
        </button>
      </div>
    </div>
  );
};

export default SelectDate;
