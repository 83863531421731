import React, { useState } from 'react';
import { Avatar } from '@material-ui/core';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import css from './ListingHeader.module.css';
import listImg from '../../assets/list-bg.svg';
import youtubeButton from '../../assets/youtube-button.png';
import UploadMediaListing from '../UploadMedia/UploadMediaListing';
import Modal from '@material-ui/core/Modal';
import AddVideoUrl from '../ListingServiceOffered/AddVideoUrl';
import classNames from 'classnames';
import { RWebShare } from 'react-web-share';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const isAuthenticated = true;
const ListingHeader = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isVideoPlay, setVideoPlay] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const {
    currentListing: { images, attributes },
    location
  } = props;
  const videoLinks = attributes?.publicData?.videoUrls
  const modelClasses = classNames(isVideoPlay ? css.modalContentVideo : css.modalContent);

  const showImages = () => {
    let imageArr = [];
    if (images.length > 0) {
      for (let i = 0; i < 3; i++) {
        if (images[i]) {
          imageArr.push(
            <img
              src={images[i]?.attributes?.variants['scaled-large']?.url}
              alt=""
              className={css.img}
            />
          );
        } else {
          imageArr.push(<UploadMediaListing classes={css.img + ' ' + css.buttonImage} image />);
        }
      }
    }
    return imageArr;
  };
  const handleThumbnailClick = (url) => {
    // Handle thumbnail click event
    console.log("Thumbnail clicked, URL:", url);
    setModalOpen(true);
    setVideoPlay(true);
    setCurrentVideoUrl(url);
  };
  const getYouTubeVideoId = (url) => {
    // Regular expression to match YouTube video ID
    // const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/.+\/|(?:v|e(?:mbed|d)?)\/|.*[?&]v=|live\/)|youtu\.be\/)([^&?\n]+)/;

    // Extract video ID using match() method
    const match = url.match(regExp);

    // Return video ID if match is found, otherwise return null
    return match ? match[1] : null;
  }


  const showVideoThumbnail = () => {
    const videoUrls = attributes?.publicData?.videoUrls
    console.log('videoUrls',videoUrls)
    let videosThumbnail = []
    if(videoUrls){
      videosThumbnail.push(
        videoUrls?.link2 ? 
        <div className={css.overLayImage}  onClick={() => handleThumbnailClick(videoUrls?.link2)} >
          <img 
            className={css.imgYoutubeButton}
            src={youtubeButton}
            alt="thumbnail"
            />
          <img 
            src={`https://img.youtube.com/vi/${getYouTubeVideoId(videoUrls?.link2)}/0.jpg`}
            alt="thumbnail"
            className={css.img}
           
          />
        </div> : <UploadMediaListing classes={css.img + ' ' + css.buttonImage} image />,
        videoUrls?.link3 ? 
        <div className={css.overLayImage} onClick={() => handleThumbnailClick(videoUrls?.link3)} >
          <img 
            className={css.imgYoutubeButton}
            src={youtubeButton}
            alt="thumbnail"
            />
          <img
            src={`https://img.youtube.com/vi/${getYouTubeVideoId(videoUrls?.link3)}/0.jpg`}
            alt="thumbnail"
            className={css.img}
            
          />
        </div> : <UploadMediaListing classes={css.img + ' ' + css.buttonImage} image />,
        videoUrls?.link4 ? 
        <div className={css.overLayImage} onClick={() => handleThumbnailClick(videoUrls?.link4)} >
          <img 
            className={css.imgYoutubeButton}
            src={youtubeButton}
            alt="thumbnail"
            />
          <img
            src={`https://img.youtube.com/vi/${getYouTubeVideoId(videoUrls?.link4)}/0.jpg`}
            alt="thumbnail"
            className={css.img}
            
          />
        </div> : <UploadMediaListing classes={css.img + ' ' + css.buttonImage} image />
      );
    }else{
      videosThumbnail.push(
        <UploadMediaListing classes={css.img + ' ' + css.buttonImage} image />,
        <UploadMediaListing classes={css.img + ' ' + css.buttonImage} image />,
        <UploadMediaListing classes={css.img + ' ' + css.buttonImage} image />
      )
    }
    return videosThumbnail
  };

  const extractYouTubeVideoId = (url) => {
    const match = url.match(/(?:live\/|v=)([a-zA-Z0-9_-]{11})/);
    return match ? 'https://youtu.be/' + match[1] : null;
  }


  return (
    <div className={css.ListingHeaderMain}>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={css.modalContentCenter}
      >
        <div className={modelClasses}>
          <span
            style={{ position: 'absolute', top: 10, right: 20, cursor: 'pointer' }}
            onClick={() => setModalOpen(false)}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 1L1 13M1 1L13 13"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          {
            isVideoPlay ? 
            <> { currentVideoUrl.includes('/live/') ? <VideoPlayer url={extractYouTubeVideoId(currentVideoUrl)} /> :
              <VideoPlayer url={currentVideoUrl} /> }
            </> 
            : <AddVideoUrl uploadHandle={() => {}} closeModal={() => setModalOpen(false)} />
          }
        </div>
      </Modal>
      <div className={css.ListingHeader}>
        <p className={css.ListerName}>
          {/* {capitalizeFirstLetter(props.title)} */}
          {/* <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: 'transparent', marginLeft: 10 }}
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.9932 4.13581C8.9938 1.7984 5.65975 1.16964 3.15469 3.31001C0.649644 5.45038 0.296968 9.02898 2.2642 11.5604C3.89982 13.6651 8.84977 18.1041 10.4721 19.5408C10.6536 19.7016 10.7444 19.7819 10.8502 19.8135C10.9426 19.8411 11.0437 19.8411 11.1361 19.8135C11.2419 19.7819 11.3327 19.7016 11.5142 19.5408C13.1365 18.1041 18.0865 13.6651 19.7221 11.5604C21.6893 9.02898 21.3797 5.42787 18.8316 3.31001C16.2835 1.19216 12.9925 1.7984 10.9932 4.13581Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg> */}
        </p>
        {/* <RWebShare
          data={{
            text: 'Worship Serve',
            url: `https://www.worshipserve.com${location.pathname}`,
            title: 'Worship Serve',
          }}
          onClick={() => {
            console.log('shared successfully!');
          }}
        >
          <button>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: 'transparent', marginRight: 10 }}
            >
              <path
                d="M16.5 9V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V9M12.3333 4.83333L9 1.5M9 1.5L5.66667 4.83333M9 1.5V11.5"
                stroke="#6941C6"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Share
          </button>
        </RWebShare> */}
      </div>

      <div className={css.mediaContainer}>
        <div className={css.videoContainer}>
          {/* {true && props.videoUrl ? (
            props.videoUrl.includes('/live/') ? <VideoPlayer url={extractYouTubeVideoId(props.videoUrl)} /> :
            <VideoPlayer url={props.videoUrl} />
          ) : (
            <UploadMediaListing video handle={() => setModalOpen(true)} />
          )} */}
          { videoLinks?.link1 ? 
            videoLinks?.link1.includes('/live/') ? <VideoPlayer url={extractYouTubeVideoId(videoLinks?.link1)} /> :
            <VideoPlayer url={videoLinks?.link1} /> :
            <UploadMediaListing video handle={() => setModalOpen(true)} />
          }
        </div>
        {isAuthenticated && false ? (
          <div className={css.imgContainer}>
            <img src={listImg} alt="" className={css.img} />
            <img src={listImg} alt="" className={css.img} />
            <img src={listImg} alt="" className={css.img} />
          </div>
        ) : (
          <div className={css.imgContainer}>
            {showVideoThumbnail()}
            {/* <UploadMediaListing classes={css.img + ' ' + css.buttonImage} image />
            <UploadMediaListing classes={css.img + ' ' + css.buttonImage} image />
            <UploadMediaListing classes={css.img + ' ' + css.buttonImage} image /> */}
          </div>
        )}
      </div>

      <div className={css.alignHeader}>
        <Avatar className={css.Avatar} src={props.userImage}>
          {props.userImage[0].toUpperCase()}
        </Avatar>
        <div className={css.leftMarginUser}>
          <div className={css.userName}>
            <p>{capitalizeFirstLetter(props.title)}</p>
            {props?.musicStyles.slice(0, 9).map((musicName, ind) => {
              return (
                <span className={css.badge}>
                  {capitalizeFirstLetter(musicName)
                    .replaceAll('_', ' ')
                    .replaceAll('christianc', 'christian')}
                </span>
              );
            })}
          </div>
          <p className={css.userDesc}>
            Bio: {props?.currentAuthor?.attributes?.profile?.bio || ''}
          </p>
        </div>
        
      </div>
      
    </div>
  );
};

export default ListingHeader;
