import React from 'react';

const Add = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fill: 'none',
        marginRight: 5,
      }}
    >
      <path
        d="M7.5 1.16666V12.8333M1.66667 6.99999H13.3333"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Add;
