import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Form, Field } from 'react-final-form';

const CustomField = ({ name, placeholder, startIcon, endIcon, label, onChange, value }) => {
  return (
    <div>
      <label className="label">{label}</label>
      <Field name={name} component="input" type="text">
        {props => {
          return (
            <div>
              <TextField
                variant="standard"
                date
                className="customField"
                name={props.input.name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{startIcon}</InputAdornment>,
                  endAdornment: <InputAdornment position="end">{endIcon}</InputAdornment>,
                  disableUnderline: true,
                }}
              />
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default CustomField;
