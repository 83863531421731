import React from 'react';
import moment from 'moment';
import Add from '../Icons/Add';
import Calendar from '../Icons/Calendar';
import css from './ListingServiceOffered.module.css';
import EstimatedBreakdownMaybe from '../../forms/BookingTimeForm/EstimatedBreakdownMaybe';
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const BookService = props => {
  const {
    requestHandler,
    selectDateHandler,
    openModal,
    title,
    bookingState,
    onContactUser,
  } = props;
  // const timeSelectionDiff =
  //   parseInt(bookingState?.selectedDate?.bookingEndTime) -
  //   parseInt(bookingState?.selectedDate?.bookingStartTime);

  const timeSelectionDiff = bookingState?.selectedDate?.hours;

  // const servicePrice = props?.serviceWithPrice[bookingState?.selectedService];
  const servicePrice = props?.price;
  const getFormattedDate = () => {
    if (bookingState.bookingDatesTimes.length) {
      const startDate = moment(bookingState?.bookingDatesTimes.at(0)?.bookingStartDate).date();
      const endDate = moment(bookingState?.bookingDatesTimes.at(-1)?.bookingEndDate).date();
      const startMonth = moment(bookingState?.bookingDatesTimes.at(0)?.bookingStartDate)
        .month('String')
        .format('MMM');
      const year = moment(bookingState?.bookingDatesTimes.at(0)?.bookingStartDate).year();
      return `${startMonth} ${startDate} , ${year}`;
    }

    return 'Select date and times';
  };

  // let isDisabledButton = bookingState?.selectedService == '' ? css.disabled : ' ';
  let isDisabledButton = false;

  // let isDisabledButtonRequest =
  //   bookingState?.selectedService == '' || !bookingState?.selectedDate?.bookingStartDate
  //     ? css.disabled
  //     : ' ';
  let isDisabledButtonRequest = !bookingState?.selectedDate?.bookingStartDate ? css.disabled : ' ';
  return (
    <React.Fragment>
      <p className={css.bookingTitle}>
        {/* <span style={{ marginBottom: 10, display: 'inline-block' }}>${servicePrice}</span> <br /> */}
        Book {title}{' '}
      </p>
      {!bookingState?.selectedDate && (
        <p className={css.bookingDesc}>
          Ready to book {title}? Select the appropriate service and the date ranges below.
        </p>
      )}
      <div className={css.bookingDates}>
        {/* {bookingState?.selectedDate && (
          <>
            <h4 className={css.totalPrice}>${timeSelectionDiff * servicePrice}</h4>
            <p className={css.bookingCost}>
              You are booking {title} as a{' '}
              {bookingState?.selectedService
                ? capitalizeFirstLetter(bookingState?.selectedService).replaceAll('_', ' ')
                : ''}{' '}
              for {timeSelectionDiff} hour at ${servicePrice}/hour
            </p>
          </>
        )} */}

        <button className={css.button} onClick={requestHandler}>
          <Add />{' '}
          {bookingState?.selectedService
            ? capitalizeFirstLetter(bookingState?.selectedService).replaceAll('_', ' ')
            : 'Select Service'}
        </button>
        
        <button className={css.button + ' ' + isDisabledButton} onClick={selectDateHandler}>
          <Calendar /> {getFormattedDate()}
        </button>
        {
          bookingState?.bookingDatesTimes?.length > 0 ? 
          <button className={css.button + ' ' + isDisabledButton} onClick={selectDateHandler}>
            <Calendar /> Add another date
          </button>
          : <></>
        }

        <EstimatedBreakdownMaybe
          bookingData={{
            startDate: bookingState?.bookingDatesTimes.at(0)?.bookingStartDate,
            endDate: bookingState?.bookingDatesTimes.at(0)?.bookingEndDate,
            selectedDates: bookingState?.bookingDatesTimes,
            bookingState:bookingState,
            timeZone: props.timeZone,
            unitType: props.unitType,
            bookingData1: null,
          }}
          lineItems={props.lineItems}
        />

        <div className={css.requestBooking}>
          <button
            className={css.button + ' ' + css.bookingButton + ' ' + isDisabledButtonRequest}
            onClick={() => openModal(true)}
          >
            {' '}
            Request to book
          </button>
          <button className={css.button + ' ' + css.contact} onClick={() => onContactUser()}>
            Contact musician
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BookService;
