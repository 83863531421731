import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
// import AddedOptions from '../ListingForm/AddedOptions';
// import CustomField from '../ListingForm/CustomField';
import moment from 'moment';
import CalendarIcon from '../../components/Icons/Calendar';
import CustomSelect from '../ListingForm/CustomSelect';
import Checkbox from '@material-ui/core/Checkbox';
import css from './BookingWizard.module.css';
import Calendar from 'react-calendar';
const DeleteIcon = () => (
  <span>
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: 'none' }}
    >
      <path
        d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5"
        stroke="#F97066"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
);

const DownArrow = props => (
  <p>
    <span style={{ marginRight: '5px' }}>{props.time}</span>
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: 'none' }}
    >
      <path
        d="M1 1.5L6 6.5L11 1.5"
        stroke="#667085"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </p>
);

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const WizardSelectService = props => {
  const { isCheckbox, options, price, availableStartTimes } = props;
  const [showDate, setShowDate] = useState(false);
  const [date, setDate] = useState(props.bookingState.reherical_date);

  const handleDateChange = (value, event) => {
    setDate(value);
    props.setBookingState({ ...props.bookingState, reherical_date: value });
    setShowDate(false);
  };

  let showRehersalDate = () => {
    let dateLabel = date != '' ? moment(date).format('MMM DD,YYYY') : 'Date';
    return dateLabel;
  };

  const SelectedOrNot = () => {
    return isCheckbox ? (
      <div>
        <p className={css.checkedService}>
          <Checkbox
            value={props.bookingState?.rehericaltime}
            checked={props.bookingState?.rehericaltime}
            onChange={props.onChangeCheckbox}
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
          <span>
            Yes, I need to add rehearsal time <span className={css.perHourRate}>${price}/hour</span>
          </span>
        </p>
      </div>
    ) : (
      <Field name={'name'} component="select" type="input" className="customField">
        <option value="">Select</option>
        {options.map((val, ind) => (
          <option value={val} key={ind}>
            {capitalizeFirstLetter(val).replaceAll('_', ' ')}
          </option>
        ))}
      </Field>
    );
  };

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  function generateAMPMTimes() {
    const times = [];
  
    for (let i = 0; i < 24 * 2; i++) {
      const hour = Math.floor(i / 2) % 12 || 12; // Calculate the hour
      const ampm = i < 12 * 2 ? 'AM' : 'PM'; // Determine AM or PM
      const minute = (i % 2) * 30; // Calculate the minute
  
      const formattedMinute = minute < 10 ? `0${minute}` : minute; // Format the minute part
      const time = `${hour}:${formattedMinute} ${ampm}`;
  
      times.push(time);
    }
  
    return times;
  }
  

  const randomTimeGenerate = () => {
    // Example usage:
  const ampmTimes = generateAMPMTimes();
  console.log('ampmTimes',ampmTimes);
  
    let start = randomIntFromInterval(1, randomIntFromInterval(4, 20));
    let arr = generateAMPMTimes();
    let newUniqueArr = [];
    for (let val of arr) {
      let obj = {};
      obj.timeOfDay = val;
      obj.timestamp = val;
      newUniqueArr.push(obj);
    }
    return newUniqueArr;
  };

  const randomTimeArr = randomTimeGenerate();
  console.log('randomTimeArr', randomTimeArr);
  return (
    <div>
      <Form
        onSubmit={() => {}}
        initialValues={{ stooge: 'larry', employed: false }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {!isCheckbox && <label className="label">Select Service</label>}
            {SelectedOrNot()}
            <div className={css.WizardSelectServiceContainer}>
              <div className={css.WizardSelectService}>
                {/* <CustomField
                  value="April 1-5, 2022"
                  name="listing_name"
                  placeholder="April 1-5, 2022"
                  startIcon={<CalendarIcon />}
                /> */}
                <div style={{ position: 'relative' }}>
                  <button className="customField reherse" onClick={() => setShowDate(true)}>
                    <CalendarIcon /> {showRehersalDate()}
                  </button>
                  {showDate && (
                    <div className="calendarContainer">
                      <Calendar value={date} onChange={handleDateChange} />
                    </div>
                  )}
                </div>
                <CustomSelect
                  name={'props.day'}
                  // endIcon={<DownArrow time="AM" />}
                  options={randomTimeArr}
                  onChange={(e)=>{
                    props.setBookingState({...props.bookingState,rehericalStartTime:e.target.value})
                  }}
                />
                -
                <CustomSelect
                  name={'props.day'}
                  // endIcon={<DownArrow time="PM" />}
                  options={randomTimeArr}
                  onChange={(e)=>{
                    props.setBookingState({...props.bookingState,rehericalEndTime:e.target.value})
                  }}
                />
              </div>
              <DeleteIcon />
            </div>
            {/* <button className={css.button + ' ' + css.addDate}>
              <Calendar /> Add Date
            </button> */}
          </form>
        )}
      />
    </div>
  );
};

export default WizardSelectService;
