import React, { useState } from 'react';
import css from './BookingWizard.module.css';
import { Wizard, useWizard } from 'react-use-wizard';
import { Avatar } from '@material-ui/core';
import WizardSelectService from './WizardSelectService';
import { Form, Field } from 'react-final-form';
import CustomField from '../ListingForm/CustomField';
import CustomSelect from '../ListingForm/CustomSelect';
const DownArrow = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: 'none' }}
  >
    <path
      d="M1 1.5L6 6.5L11 1.5"
      stroke="#667085"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Step1 = props => {
  const { handleStep, previousStep, nextStep } = useWizard();

  return (
    <div className={css.Step1}>
      <Avatar className={css.Avatar}>C</Avatar>
      <p className={css.stepHeading}>Tell {props.title} a little more about your booking request</p>
      <button className={css.button + ' ' + css.action} onClick={nextStep}>
        Continue{' '}
      </button>
      <button className={css.button} onClick={props.handleClose}>
        Nevermind{' '}
      </button>
    </div>
  );
};

const Step2 = props => {
  const { handleStep, previousStep, nextStep } = useWizard();
  return (
    <div className={css.Step2}>
      <p className={css.stepHeading}>Confirm booking details</p>
      <WizardSelectService options={props.serviceOptions} />
      <button className={css.button + ' ' + css.action} onClick={nextStep}>
        Continue{' '}
      </button>
      <button className={css.button} onClick={previousStep}>
        Back
      </button>
    </div>
  );
};

const Step3 = props => {
  const { handleStep, previousStep, nextStep } = useWizard();
  return (
    <div className={css.Step2}>
      <p className={css.stepHeading}>Do you need to add rehearsal time?</p>
      <WizardSelectService
        isCheckbox
        onChangeCheckbox={props.onChangeCheckbox}
        bookingState={props.bookingState}
        price={props.price}
        availableStartTimes={props.availableStartTimes}
        setBookingState={props.setBookingState}
      />
      <button className={css.button + ' ' + css.action} onClick={nextStep}>
        Continue{' '}
      </button>
      <button className={css.button} onClick={previousStep}>
        Back
      </button>
    </div>
  );
};

const Step4 = props => {
  let { description, description_church, address, services, city, state, zip} = props.bookingState
  const { handleStep, previousStep, nextStep } = useWizard();
  const [value, setValue] = useState(description_church);
  const [valueDesc, setValueDesc] = useState(description);
  const [valueDesc2, setValueDesc2] = useState(address);
  const [zipValue, setZipValue] = useState(zip);
  const [servicesValue, setValueService] = useState(services);
  const [cityValue, setCity] = useState(city);
  const [cityState, setCityState] = useState(state);

  const changeCityHandler = e => {
    setCity(e.target.value);
  };

  const changeCityStateHandler = e => {
    setCityState(e.target.value);
  };

  const changeDescHandler = e => {
    setValue(e.target.value);
  };

  const changeDescHandlerDesc = e => {
    setValueDesc(e.target.value);
  };

  const changeDescHandlerDesc2 = e => {
    setValueDesc2(e.target.value);
  };

  const changeZipHandler = e => {
    setZipValue(e.target.value);
  };

  const servicesHandler = e => {
    setValueService(e.target.value);
  };
  return (
    <div className={css.Step2}>
      <p className={css.stepHeading}>Confirm church details</p>
      <div>
        <Form
          onSubmit={() => {}}
          initialValues={{ stooge: 'larry', employed: false }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <CustomField
                label="Church Name"
                value={value}
                name="Church Name"
                placeholder=""
                onChange={changeDescHandler}
              />
              <CustomField
                label="Street Address 1"
                value={valueDesc}
                name="Street Address 1"
                placeholder=""
                onChange={changeDescHandlerDesc}
              />
              <CustomField label="Street Address 2" name="Street Address 2" value={valueDesc2} placeholder="" onChange={changeDescHandlerDesc2}/>
              <div className={css.displayFlex}>
                <CustomField label="City" name="City" placeholder="" value={cityValue} onChange={changeCityHandler} />
                <div className={css.flex1}>
                  {/* <CustomSelect
                    label="State"
                    value="Select"
                    name="timezone"
                    endIcon={<DownArrow />}
                    options={['Select']}
                  /> */}
                  <CustomField
                    label="State"
                    name="State"
                    placeholder=""
                    value={cityState}
                    onChange={changeCityStateHandler}
                  />
                </div>
              </div>
              <CustomField label="Zip Code" name="Zip Code" value={zipValue} placeholder="" onChange={changeZipHandler}/>
              <CustomField
                label="Number of services?"
                value={servicesValue}
                name="Number of services?"
                placeholder=""
                onChange={servicesHandler}
              />
            </form>
          )}
        />
      </div>
      <button
        className={css.button + ' ' + css.action}
        onClick={() => {
          props.setDescriptionChurch({ value, valueDesc, valueDesc2, servicesValue, cityValue, cityState, zipValue });
          nextStep();
        }}
      >
        Continue{' '}
      </button>
      <button className={css.button} onClick={previousStep}>
        Back
      </button>
    </div>
  );
};

const Step5 = props => {
  const { handleStep, previousStep, nextStep } = useWizard();
  return (
    <div className={css.Step2}>
      <p className={css.stepHeading}>Any other details?</p>
      <label className="label">Let {props.title} know...</label>
      <textarea
        name="#"
        className={css.textarea}
        placeholder="Project details..."
        onChange={e => {
          props.setOtherDetails(e.target.value);
        }}
      ></textarea>
      <button className={css.button + ' ' + css.action} onClick={props.handleClose}>
        Book {props.title}{' '}
      </button>
      <button className={css.button} onClick={previousStep}>
        Back
      </button>
    </div>
  );
};

const StepProgress = props => {
  const { handleStep, activeStep, stepCount } = useWizard();
  const [loadingState, setLoadingState] = useState(0);
  handleStep(() => {
    const calcProgress = ((activeStep + 1) / stepCount) * 100;
    setLoadingState(calcProgress);
  });
  return (
    <div className={css.progressContainer}>
      <div className={css.progress} style={{ width: `${loadingState}%` }}></div>
    </div>
  );
};

const BookingWizard = props => {
  const { setOtherDetails, bookingState,setBookingState, serviceWithPrice, availableStartTimes } = props;
  let serviceOptions = Object.keys(serviceWithPrice) || [];

  return (
    <div>
      <Wizard header={<StepProgress />}>
        <Step1 handleClose={props.handleClose} title={props.title} />
        {/* <Step2 serviceOptions={serviceOptions} /> */}
        <Step4
          handleClose={props.handleClose}
          setDescriptionChurchDesc={props.setDescriptionChurchDesc}
          setDescriptionChurch={props.setDescriptionChurch}
          bookingState={bookingState}
        />
        <Step3
          handleClose={props.handleClose}
          onChangeCheckbox={props.onChangeCheckbox}
          bookingState={bookingState}
          price={props.price}
          availableStartTimes={props.availableStartTimes}
          setBookingState={setBookingState}
        />
        <Step5
          handleClose={props.handleSubmit}
          setOtherDetails={setOtherDetails}
          title={props.title}
        />
      </Wizard>
    </div>
  );
};

export default BookingWizard;
