import React from 'react';

const Star = props => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.17 14.75C12.0501 14.7505 11.9317 14.7222 11.825 14.6675L7.99999 12.665L4.17499 14.6675C3.92159 14.8007 3.61444 14.7781 3.38332 14.6091C3.15219 14.4402 3.03742 14.1544 3.08749 13.8725L3.83749 9.64997L0.747494 6.64997C0.551036 6.45392 0.478907 6.16541 0.559994 5.89997C0.648677 5.62804 0.884311 5.43022 1.16749 5.38997L5.44249 4.76747L7.32499 0.91997C7.45031 0.661227 7.7125 0.496887 7.99999 0.496887C8.28749 0.496887 8.54968 0.661227 8.67499 0.91997L10.58 4.75997L14.855 5.38247C15.1382 5.42272 15.3738 5.62054 15.4625 5.89247C15.5436 6.15791 15.4715 6.44642 15.275 6.64247L12.185 9.64247L12.935 13.865C12.9897 14.1521 12.8726 14.4448 12.635 14.615C12.4992 14.7101 12.3357 14.7576 12.17 14.75Z"
        fill={props.filled ? '#FFC107' : '#919EAB'}
      />
    </svg>
  );
};

export default Star;
